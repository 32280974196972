import React, { useState, useRef, useEffect } from 'react'
import { useDropzone } from 'react-dropzone';

import Img from "gatsby-image"
import Formsy from 'formsy-react';
import axios from "axios"

import FormsyInput from "./../Formsy/formsyInput"

import { MultiSelect } from "react-multi-select-component";


import "./ApplicantForm.css";


function CareerDayParticipant(props) {
    const data = props;
    const formRef = useRef(null);

    console.log(data)



    const [canSubmit, setCanSubmit] = useState(false);
    const [canSubmit2, setCanSubmit2] = useState(false);
    const [loading, setLoading] = useState(false);
    const [privacyTermsStatus, setPrivacyTermsStatus] = useState(false);
    const [cvOptIn, setCvOptIn] = useState(false);

    const [emailSent, setEmailSent] = useState(false);
    const [emailFailed, setEmailFailed] = useState(false);
    const [emailResponse, setEmailResponse] = useState("");
    const [showErrorMessage, setShowErrorMessage] = useState(false);


    useEffect(() => {
        if (canSubmit) {
            setShowErrorMessage(false)
        }
    }, [canSubmit])

    useEffect(() => {

        if (privacyTermsStatus && canSubmit) {
            setCanSubmit2(true)
        } else if (privacyTermsStatus && canSubmit ) {
            setCanSubmit2(true)
        } else {
            setCanSubmit2(false)
        }
    }, [privacyTermsStatus, cvOptIn])


    function disableButton() {
        setCanSubmit(false);
    }

    function enableButton() {
        setCanSubmit(true);
    }

    function submit(model) {

        if (model.lastName && model.name && model.email) {
            sendApplication(model);
        } else if ((model.lastName && model.name && model.email)) {
            sendApplication(model);
        } else if (model.lastName && model.name && model.email) {
            sendApplication(model);
        } else {
            setShowErrorMessage(true)
        }
    }

    function sendApplication(model) {
        // setLoading(true)

        console.log(model)

        let formData = new FormData();
        
        formData.append('model', JSON.stringify(model));

    
        let payload = {
            ...model,
            language: props.language
        }

        axios.post(`https://f2.enimeris.com/wp-json/myplugin/v1/addParticipants`, formData)
            .then(function (response) {
                console.log(response)
                // handle success
                setEmailFailed(false)
                setLoading(false)
                setEmailSent(true)
                setEmailResponse(response.data)
            })
            .catch(function (error) {
                // handle error
                setLoading(false)
                setEmailFailed(true)
                setEmailResponse("Υπήρξε πρόβλημα κατα την αποστολή. Δοκιμάστε αργότερα")
            })
    }

    function test(e) {
        e.preventDefault();
        setShowErrorMessage(true)
    }


    return (
        <div className={`${emailSent ? "email-sent-container" : "cf-container"} py-24`}>
            <div className={`m-auto ${emailSent ? "max-w-none" : "max-w-xl"} px-12 relative`} >
                {loading && (
                    <div className="loader-container">
                        <div className="loader">Loading...</div>
                    </div>
                )}

                {emailFailed && (
                    <p style={{ color: "#b70000", fontSize: "1.2rem" }}>{emailResponse}</p>
                )}

                {!emailSent
                    ?
                    <Formsy
                        onValidSubmit={submit}
                        onValid={enableButton}
                        onInvalid={disableButton}
                        className={loading ? "opacity-25" : "opacity-100"}
                        ref={formRef}
                    >
                        <h2 className="text-4xl text-white text-center">
                            {data.textData.title}
                        </h2>
                        <div>
                            <p className="text-md text-white text-center">
                                {data.textData.subtitle}
                            </p>
                        </div>
                        <div className="mt-12">
                            <FormsyInput
                                name="name"
                                placeholder="Όνομα *"
                                classes="mt-4"
                                required
                            />
                            <FormsyInput
                                name="lastName"
                                placeholder="Επώνυμο *"
                                classes="mt-4"
                                required
                            />
                            <FormsyInput
                                name="company"
                                placeholder="Εταιρεία *"
                                classes="mt-4"
                                required
                            />
                            <FormsyInput
                                name="email"
                                validations={"isEmail"}
                                validationErrors={{
                                    isEmail: "Το email δεν είναι έγκυρο",
                                }}
                                placeholder="Email *"
                                classes="mt-4"
                                required
                            />
                            {props.allowPhoneNumber && (
                                <FormsyInput
                                    name="telephone"
                                    validations={"minLength:10"}
                                    type="number"
                                    validationErrors={{
                                        minLength: "Το τηλέφωνο δεν είναι έγκυρο",
                                    }}
                                    placeholder="Τηλέφωνο *"
                                    classes="mt-4"
                                    required
                                />

                            )}

                            <div className="mt-8">
                                <input
                                    name="privacy-terms"
                                    type="checkbox"
                                    checked={privacyTermsStatus}
                                    onChange={() => setPrivacyTermsStatus(!privacyTermsStatus)}
                                />
                                <span className="pl-2 text-white">Αποδέχομαι την
                                    <a href="https://enimeris.com/politiki-aporitou/" target="_blank" className="px-1 underline">Πολιτική Απορρήτου</a>
                                    και τους
                                    <a href="https://enimeris.com/oroi-kai-proipotheseis/" target="_blank" className="pl-1 underline">Όρους και Προϋποθέσεις Χρήσης</a>.
                                </span>
                            </div>


                        </div>
                        {(canSubmit && canSubmit2)
                            ?
                            <button className={"bttn-active cf-bttn"}>
                                Υποβολή
                            </button>
                            :
                            <button className={"cf-bttn"} onClick={(e) => test(e)}>
                                Υποβολή
                            </button>
                        }
                        {showErrorMessage && (
                            <p className="text-center mt-8" style={{ color: "#b70000", fontSize: "1.2rem", }}>Συμπληρώστε όλα τα απαραίτητα πεδία (*)</p>
                        )}
                    </Formsy>
                    :
                    <div >
                        <div className="email-response" dangerouslySetInnerHTML={{ __html: emailResponse }} />
                    </div>
                }

            </div>
        </div>
    )
}

export default CareerDayParticipant;